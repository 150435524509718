import BlueBirdPromise from "bluebird";
import log from "loglevel";

export const InternalPromise = BlueBirdPromise;
export const getEmptyPromise = () => new InternalPromise((resolve, reject) => resolve({}));

class ResponseError extends Error {
  constructor(response = null, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError);
    }

    if (!response) {
      // If no response given, there's probably something wrong with the connection
      this.errorInfo = {
        response: null,
        error: "No response",
        status: null,
        text: "No response, the connection seems lost",
        date: new Date(),
      };
    } else {
      // Custom debugging information
      this.errorInfo = {
        response,
        error: response.error,
        status: response.error.status,
        text: response.text,
        date: new Date(),
      };
    }
  }
}

export const requestToPromise = (request, reqName) =>
  new InternalPromise((resolve, reject) => {
    try {
      request.end((error, response) => {
        if (error) {
          const errorMsg = `[DAL/${reqName}] XHR failed`;
          const responseError = new ResponseError(response, error);
          log.error(errorMsg, responseError, responseError.errorInfo);

          reject(responseError);
        } else {
          const responseMsg = `[DAL/${reqName}] XHR success`;
          log.debug(responseMsg, response);
          resolve(response);
        }
      });
    } catch (ex) {
      reject(ex);
    }
  });

export const addDateQueryToUrl = (url, startDate, endDate) => {
  let newUrl = url;
  if (startDate || endDate) {
    const queries = [];
    if (startDate) queries.push(`start=${startDate.format("YYYY-MM-DD")}`);
    if (endDate) queries.push(`end=${endDate.format("YYYY-MM-DD")}`);

    newUrl = `${newUrl}?${queries.join("&")}`;
  }
  return newUrl;
};

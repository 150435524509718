import moment from "app/utils/momentLocalized";
import humanizeDuration from "humanize-duration";

const shortHumanizer = humanizeDuration.humanizer({
  language: "short_en",
  languages: {
    short_en: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
    short_nl: {
      y: () => "j",
      mo: () => "ma",
      w: () => "w",
      d: () => "d",
      h: () => "u",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

function renderDuration(d, { renderZero = true, momentUnit = "minutes", showSeconds = false, short = true } = {}) {
  if (d === null || d === undefined) return null;
  if (!renderZero && d === 0) return null;

  let duration = d;
  if (!moment.isDuration(d)) {
    duration = moment.duration(duration, momentUnit);
  }

  const durationUnits = ["h", "m"];
  if (showSeconds) durationUnits.push("s");

  const language = `${short ? "short_" : ""}${window.locale || "en"}`;

  const options = {
    language,
    units: durationUnits,
    round: true,
  };

  if (short) {
    options.spacer = "";
    options.delimiter = " ";
  }

  return `${d < 0 ? "minus " : ""}${shortHumanizer(duration, options)}`;
}

export function renderDurationLong(d, options) {
  return renderDuration(d, { ...options, short: false });
}

export default renderDuration;

import PlanToolsDal from "./dal";
import { getEmptyPromise } from "app/utils/dal";

export const actionTypes = {
  // TODO split calculations from planTools

  START_CALCULATION: "planTools/START_CALCULATION",
  PAUSE_CALCULATION: "planTools/PAUSE_CALCULATION",
  CANCEL_CALCULATION: "planTools/CANCEL_CALCULATION",
  CONTINUE_CALCULATION: "planTools/CONTINUE_CALCULATION",
  ARCHIVE_CALCULATION: "planTools/ARCHIVE_CALCULATION",
  UNARCHIVE_CALCULATION: "planTools/UNARCHIVE_CALCULATION",
  UPDATE_CALCULATION_DISPLAY_NAME: "planTools/UPDATE_CALCULATION_DISPLAY_NAME",
  PREVIEW_CALCULATION: "planTools/PREVIEW_CALCULATION",
  RERUN_CALCULATION: "planTools/RERUN_CALCULATION",

  REVIEW_CALCULATION_ACTIONS: "planTools/REVIEW_CALCULATION_ACTIONS",
  REVIEW_CALCULATION_ITERATIONS: "planTools/REVIEW_CALCULATION_ITERATIONS",
  REVIEW_CALCULATION_OUT: "planTools/REVIEW_CALCULATION_OUT",
  REVIEW_CALCULATION_STATS: "planTools/REVIEW_CALCULATION_STATS",
  REVIEW_CALCULATION_CONSTRUCT_CUSTOM_STATE: "planTools/REVIEW_CALCULATION_CONSTRUCT_CUSTOM_STATE",
  REVIEW_CALCULATION_REMOVE_CUSTOM_STATE: "planTools/REVIEW_CALCULATION_REMOVE_CUSTOM_STATE",

  ACCEPT_PLANNING_CHANGES: "planTools/ACCEPT_PLANNING_CHANGES",
  ACCEPT_CALCULATION_RESULTS: "planTools/ACCEPT_CALCULATION_RESULTS",
  REJECT_CALCULATION_RESULTS: "planTools/REJECT_CALCULATION_RESULTS",

  CLEAR_CALCULATION_REVIEW: "planTools/CLEAR_CALCULATION_REVIEW",

  REPLAY_ITERATION: "planTools/REPLAY_ITERATION",
  LOAD_ITERATION: "planTools/LOAD_ITERATION",
};

const actionCreators = {
  /***
   * Action creator: load annealing
   * @returns {{type: string, payload: *}}
   */

  startCalculation(snapshotId, scope, settings) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.runCalculation(snapshotId, scope, settings);
    return {
      type: actionTypes.START_CALCULATION,
      payload: actionPromise,
    };
  },

  cancelCalculation(calculationPk) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.cancelCalculation(calculationPk);
    return {
      type: actionTypes.CANCEL_CALCULATION,
      payload: actionPromise,
    };
  },

  continueCalculation(calculationPk) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.continueCalculation(calculationPk);
    return {
      type: actionTypes.CONTINUE_CALCULATION,
      payload: actionPromise,
    };
  },

  pauseCalculation(calculationPk) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.pauseCalculation(calculationPk);
    return {
      type: actionTypes.PAUSE_CALCULATION,
      payload: actionPromise,
    };
  },

  archiveCalculation(calculationPk) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.archiveCalculation(calculationPk);
    return {
      type: actionTypes.ARCHIVE_CALCULATION,
      payload: actionPromise,
    };
  },

  unarchiveCalculation(calculationPk) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.unarchiveCalculation(calculationPk);
    return {
      type: actionTypes.UNARCHIVE_CALCULATION,
      payload: actionPromise,
    };
  },

  updateCalculationDisplayName(calculationPk, displayName) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.updateCalculationDisplayName(calculationPk, displayName);
    return {
      type: actionTypes.UPDATE_CALCULATION_DISPLAY_NAME,
      payload: actionPromise,
    };
  },

  previewCalculation(snapshotName, scope) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.previewCalculation(snapshotName, scope);
    return {
      type: actionTypes.PREVIEW_CALCULATION,
      payload: actionPromise,
    };
  },

  reviewCalculationIterations(calculationId) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.reviewCalculationIterations(calculationId);
    return {
      type: actionTypes.REVIEW_CALCULATION_ITERATIONS,
      payload: actionPromise,
    };
  },

  reviewCalculationOut(calculationId) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.reviewCalculationOut(calculationId);
    return {
      type: actionTypes.REVIEW_CALCULATION_OUT,
      payload: actionPromise,
    };
  },

  reviewCalculationStats(calculationId) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.reviewCalculationStats(calculationId);
    return {
      type: actionTypes.REVIEW_CALCULATION_STATS,
      payload: actionPromise,
    };
  },

  rerunCalculation(calculationId, calculationSettings) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.rerunCalculation(calculationId, calculationSettings);
    return {
      type: actionTypes.RERUN_CALCULATION,
      payload: actionPromise,
    };
  },

  loadIteration(calculationId, iterationNr) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.loadIteration(calculationId, iterationNr);
    return {
      type: actionTypes.LOAD_ITERATION,
      payload: actionPromise,
    };
  },

  acceptPlanningChanges({ assignmentsToCreate, assignmentsToEdit, assignmentPksToDelete }, actualStateSnapshotId) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.acceptPlanningChanges(
      {
        assignmentsToCreate,
        assignmentsToEdit,
        assignmentPksToDelete,
      },
      actualStateSnapshotId,
    );
    return {
      type: actionTypes.ACCEPT_PLANNING_CHANGES,
      payload: actionPromise,
    };
  },

  acceptCalculationResults(calculationPk) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.acceptCalculationResults(calculationPk);
    return {
      type: actionTypes.ACCEPT_CALCULATION_RESULTS,
      payload: actionPromise,
    };
  },

  rejectCalculationResults(calculationPk) {
    const dal = new PlanToolsDal();
    const actionPromise = dal.rejectCalculationResults(calculationPk);
    return {
      type: actionTypes.REJECT_CALCULATION_RESULTS,
      payload: actionPromise,
    };
  },

  clearCalculationReview() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_CALCULATION_REVIEW,
      payload: actionPromise,
    };
  },
};

export default actionCreators;

import { useMemo } from "react";
import { EuiButton } from "@elastic/eui";
import { fromJS } from "immutable";

import { euiColorMap } from "app/common/pages/ElasticUIListPage";
import { CustomIcon, CustomIconWithLabel, Icons } from "app/utils/constants";
import { renderRecurrence } from "app/features/recurrences/components/RecurrenceRuleEditor";
import renderDuration from "app/utils/renderDuration";
import renderMoment from "app/utils/renderMoment";
import { FormattedMessage } from "react-intl";

const fadedStyle = { color: "#9d9d9d" };

const renderMomentOrUnknown = (m) => (m ? renderMoment(m) : "?");

const SnapshotScopeSimpleTable = ({
  scopes,
  activeScopePk,
  onNewScope,
  onActivateScope,
  onDeleteScope,

  onAddScopeRecurrence,
  onEditScopeRecurrence,
  onDeleteScopeRecurrence,
}) => {
  const scopesSortedJS = useMemo(
    () =>
      scopes
        ? fromJS(scopes)
            .sortBy((s) => [
              s.get("current_start") ?? s.get("next_start") ?? s.get("start"),
              s.get("current_end") ?? s.get("next_end") ?? s.get("end"),
              s.get("name"),
            ])
            .toJS()
        : [],
    [scopes],
  );

  const newScopeBtn = onNewScope && (
    <EuiButton size="s" onClick={onNewScope} color={euiColorMap.success} fill>
      <CustomIconWithLabel icon={Icons.add} label={<FormattedMessage id="SnapshotScopeSimpleTable.newScope" />} />
    </EuiButton>
  );

  return (
    <table className="table table-condensed">
      <thead>
        <tr>
          <th />
          <th>
            <FormattedMessage id="fields.name" />
          </th>
          <th>
            <FormattedMessage id="fields.from" />
          </th>
          <th>
            <FormattedMessage id="fields.until" />
          </th>
          <th>{newScopeBtn}</th>
        </tr>
      </thead>
      <tbody>
        {scopesSortedJS.length > 0 ? (
          scopesSortedJS.map((scope) => {
            const {
              pk,
              name,
              start,
              end,
              recurrence,
              expires_minutes_after_start: minutesAfterStart,
              expires_minutes_before_end: minutesBeforeEnd,
              current_start,
              current_end,
              next_start,
              next_end,
            } = scope;
            const deleteScope = onDeleteScope ? () => onDeleteScope(pk) : null;
            const addRecurrence = onAddScopeRecurrence ? () => onAddScopeRecurrence(pk) : null;
            // TODO
            //  - add confirmDeleteScope
            //  - add link to edit scope in general and view more details

            const editRecurrence = recurrence && onEditScopeRecurrence ? () => onEditScopeRecurrence(scope) : null;
            // TODO add confirmDeleteRecurrence
            const deleteRecurrence =
              recurrence && onDeleteScopeRecurrence ? () => onDeleteScopeRecurrence(scope) : null;

            const editDeleteIcons = (
              <>
                {editRecurrence ? (
                  <a onClick={editRecurrence}>
                    <CustomIcon icon={Icons.edit} />
                  </a>
                ) : null}
                &nbsp;
                {deleteRecurrence ? (
                  <a onClick={deleteRecurrence}>
                    <CustomIcon icon={Icons.delete} />
                  </a>
                ) : null}
              </>
            );

            return (
              <tr key={pk}>
                <td>
                  {pk === activeScopePk ? (
                    <b>
                      <FormattedMessage id="SnapshotScopeSimpleTable.scope.active" />
                    </b>
                  ) : (
                    <a onClick={() => onActivateScope(pk)}>
                      <FormattedMessage id="SnapshotScopeSimpleTable.scope.activate" />
                    </a>
                  )}
                </td>
                <td>{name}</td>
                <td>
                  {recurrence ? (
                    <>
                      <span style={fadedStyle}>
                        {renderMomentOrUnknown(start)}
                        &nbsp; (<FormattedMessage id="SnapshotScopeSimpleTable.scope.first" />)
                      </span>
                      <br />
                      <span>
                        {renderMomentOrUnknown(current_start)}
                        &nbsp; (<FormattedMessage id="SnapshotScopeSimpleTable.scope.current" />)
                      </span>
                      <br />
                      <span style={fadedStyle}>
                        {renderMomentOrUnknown(next_start)}
                        &nbsp; (<FormattedMessage id="SnapshotScopeSimpleTable.scope.next" />)
                      </span>
                      <br />
                    </>
                  ) : (
                    renderMoment(start)
                  )}
                </td>
                <td>
                  {recurrence ? (
                    <>
                      <span style={fadedStyle}>
                        {renderMomentOrUnknown(end)} &nbsp; (
                        <FormattedMessage id="SnapshotScopeSimpleTable.scope.first" />)
                      </span>
                      <br />
                      <span>
                        {renderMomentOrUnknown(current_end)}&nbsp; (
                        <FormattedMessage id="SnapshotScopeSimpleTable.scope.current" />)
                      </span>
                      <br />
                      <span style={fadedStyle}>
                        {renderMomentOrUnknown(next_end)} &nbsp; (
                        <FormattedMessage id="SnapshotScopeSimpleTable.scope.next" />)
                      </span>
                      <br />
                    </>
                  ) : (
                    renderMoment(end)
                  )}
                </td>
                <td>
                  {deleteScope ? (
                    <a onClick={deleteScope}>
                      <FormattedMessage id="actions.Delete" />
                    </a>
                  ) : null}
                </td>
                <td>
                  {recurrence ? (
                    <>
                      <u>
                        <FormattedMessage id="SnapshotScopeSimpleTable.recurrence" />
                      </u>
                      &nbsp;&nbsp;{editDeleteIcons}
                      {renderRecurrence(recurrence)}
                      {minutesAfterStart ? (
                        <>
                          <FormattedMessage
                            id="SnapshotScopeSimpleTable.expiresAfterStart"
                            values={{ duration: renderDuration(minutesAfterStart) }}
                          />
                          <br />
                        </>
                      ) : null}
                      {minutesBeforeEnd ? (
                        <>
                          <FormattedMessage
                            id="SnapshotScopeSimpleTable.expiresBeforeEnd"
                            values={{ duration: renderDuration(minutesBeforeEnd) }}
                          />
                        </>
                      ) : null}
                    </>
                  ) : addRecurrence ? (
                    <a onClick={addRecurrence}>
                      <FormattedMessage id="SnapshotScopeSimpleTable.addRecurrence" />
                    </a>
                  ) : null}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={5}>
              <i>
                <FormattedMessage id="SnapshotScopeSimpleTable.noScopesYet" />
              </i>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default SnapshotScopeSimpleTable;

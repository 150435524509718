import { useAppActions } from "app/actions";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  getUserIsInDebugMode,
  getUserProfilePkFromUser,
  getUserProfilePlanningExportLabelsResources,
  getUserProfilePlanningExportLabelsAssignments,
} from "app/features/users/selectors";

export const useUpdateUserProfile = () => {
  const actions = useAppActions();
  const userProfilePk = useSelector(getUserProfilePkFromUser);
  const handleUpdate = useCallback(
    (values) =>
      actions.users.updateUserProfile({
        pk: userProfilePk,
        ...values,
      }),
    [userProfilePk, actions],
  );

  return handleUpdate;
};

export const useToggleDebugMode = () => {
  const debugMode = useSelector(getUserIsInDebugMode);
  const updateUserProfile = useUpdateUserProfile();
  return useCallback(() => updateUserProfile({ debug_mode: !debugMode }), [updateUserProfile, debugMode]);
};

export const useTogglePlanningExportLabelsResources = () => {
  const value = useSelector(getUserProfilePlanningExportLabelsResources);
  const updateUserProfile = useUpdateUserProfile();
  return useCallback(() => updateUserProfile({ planning_export_labels_resources: !value }), [updateUserProfile, value]);
};

export const useTogglePlanningExportLabelsAssignments = () => {
  const value = useSelector(getUserProfilePlanningExportLabelsAssignments);
  const updateUserProfile = useUpdateUserProfile();
  return useCallback(
    () => updateUserProfile({ planning_export_labels_assignments: !value }),
    [updateUserProfile, value],
  );
};
